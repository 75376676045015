import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { Link } from "gatsby";
import * as React from "react";
import { AiFillInstagram, AiOutlineGateway } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import {
  FaChartLine,
  FaFacebookSquare,
  FaKeyboard,
  FaLinkedin,
  FaPencilAlt,
  FaRecycle,
} from "react-icons/fa";

import Container from "../ui/Container";
import OurProcessCard from "./OurProcessCard";

function Footer() {
  return (
    <footer id="solutions">
      <Box>
        <Box bg="gray.600" color="white" textAlign="center" py="10">
          <Container fluid>
            <VStack spacing="10">
              <Text as="h4" fontSize="3xl" textTransform="uppercase">
                Our process
              </Text>

              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  xl: "repeat(6, 1fr)",
                }}
                gap="5"
              >
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Requirement" icon={FaPencilAlt} />
                </GridItem>
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Strategy" icon={FaRecycle} />
                </GridItem>
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Design" icon={AiOutlineGateway} />
                </GridItem>
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Develop" icon={FaKeyboard} />
                </GridItem>
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Test" icon={BsCheck2Square} />
                </GridItem>
                <GridItem
                  transition="all 0.2"
                  _hover={{
                    boxShadow: "xl",
                    transform: "scale(1.01) translateY(-1%)",
                  }}
                >
                  <OurProcessCard title="Deploy" icon={FaChartLine} />
                </GridItem>
              </Grid>
            </VStack>
          </Container>
        </Box>

        {/* LOWER FOOTER  */}

        <div className="bg-slate-900   text-white py-10">
          <Container fluid>
            {" "}
            <div className="grid  sm:grid-cols-3 gap-6">
              <div className="space-y-4">
                <h3 className="font-bold text-red-600 ">OUR SERVICES</h3>

                <p>
                  <Link
                    rel="canonical"
                    to="/website-design/"
                    className="hover:text-red-500 "
                  >
                    WEBSITE DESIGN
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/website-development/"
                    className="hover:text-red-500 "
                  >
                    WEBSITE DEVELOPMENT
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/e-commerce-design-and-development/"
                    className="hover:text-red-500 "
                  >
                    eCOMMERCE DESIGN &amp; DEVELOPMENT{" "}
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/mobile-app-design-and-development/"
                    className="hover:text-red-500 "
                  >
                    MOBILE APP DESIGN &amp; DEVELOPMENT
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/progressive-or-responsive-web-app/"
                    className="hover:text-red-500 "
                  >
                    PROGRESSIVE WEB APPLICATION
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/progressive-or-responsive-web-app/"
                    className="hover:text-red-500 "
                  >
                    WEBSITE MAINTENANCE
                  </Link>
                </p>
              </div>
              <div className="space-y-4">
                <h3 className="font-bold text-red-500">OUR SOLUTIONS</h3>

                <p>
                  <Link
                    rel="canonical"
                    to="/third-party-integration/"
                    className="hover:text-red-500"
                  >
                    THIRD PARTY INTEGRATION
                  </Link>
                </p>
                <p>
                  <Link
                    rel="canonical"
                    to="/software-development/"
                    className="hover:text-red-500 "
                  >
                    SOFTWARE DEVELOPMENT
                  </Link>
                </p>

                <p>
                  <Link
                    rel="canonical"
                    to="/support-maintenance/"
                    className="hover:text-red-500 "
                  >
                    SUPPORT AND MAINTENENCE
                  </Link>
                </p>

                <p>
                  <Link
                    rel="canonical"
                    to="/cloud-we-work/"
                    className="hover:text-red-500 "
                  >
                    CLOUD SOLUTIONS - WE WORK
                  </Link>
                </p>
              </div>

              {/* SOCIAL MEDIA SECTION */}
              <div className="space-y-4">
                <h3 className="font-bold text-red-500">SOCIAL LINKS</h3>

                <div className="flex flex-row space-x-4 ">
                  <a
                    href="https://www.facebook.com/bitdecoders"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookSquare size={40} color="#F40E3F" />
                  </a>
                  <a
                    href=" https://www.linkedin.com/company/bitdecoders"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={40} color="#F40E3F" />
                  </a>

                  <a
                    href="https://www.instagram.com/bitdecoders"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram size={45} color="#F40E3F" />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="text-center bg-gray-900 text-white  ">
          Copyright &copy; 2022 Bitdecoders. All Rights Reserved.
        </div>
      </Box>
    </footer>
  );
}

export default Footer;
