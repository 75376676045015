import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Box, Button, Flex, HStack, IconButton } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Navigation from "./Navigation";

function Header({ siteTitle }) {
  const [display, setDisplay] = useState("none");

  return (
    <HStack
      as="header"
      alignItems="center"
      justifyContent="space-between"
      p="4"
      px="14"
    >
      <Box maxW="52">
        <Link to="/">
          <StaticImage src="../../images/bitdecoders.png" alt={siteTitle} />
        </Link>
      </Box>

      <Flex
        align="center"
        display={["none", "none", "flex", "flex"]}
        top="1rem"
        right="1rem"
      >
        <Navigation />
      </Flex>

      <IconButton
        aria-label="Open Menu"
        mr={2}
        icon={<HamburgerIcon fontSize="3xl" />}
        display={["flex", "flex", "none", "none"]}
        onClick={() => setDisplay("flex")}
        size="lg"
        bgColor="white"
        alignSelf="center"
      />

      <Flex
        w="full"
        h="full"
        bgColor="white"
        pos="fixed"
        left="0"
        top="0"
        overflow="auto"
        flexDir="column"
        zIndex={10}
        display={display}
      >
        <Flex justify="flex-end">
          <IconButton
            mt={2}
            mr={2}
            area-label="CLose Menu"
            size="lg"
            icon={<CloseIcon />}
            onClick={() => setDisplay("none")}
          />
        </Flex>

        <Flex color="Black" fontWeight="bold" flexDir="column" align="center">
          <Button
            bgColor="white"
            _hover={{ bg: "red.500", color: "white" }}
            dir="row"
            onClick={() => setDisplay("none")}
          >
            <Link rel="canonical" to="/">
              HOME
            </Link>
          </Button>
          <Button
            bgColor="white"
            _hover={{ bg: "red.500", color: "white" }}
            onClick={() => setDisplay("none")}
          >
            <Link rel="canonical" to="/about/">
              ABOUT
            </Link>
          </Button>
          <Button
            _hover={{ bg: "red.500", color: "white" }}
            onClick={() => setDisplay("none")}
          >
            <Link rel="canonical" to="/#services">
              SERVICES
            </Link>
          </Button>
          <Button
            bgColor="white"
            _hover={{ bg: "red.500", color: "white" }}
            onClick={() => setDisplay("none")}
          >
            <Link rel="canonical" to="/#solutions">
              SOLUTIONS
            </Link>
          </Button>
          <Button
            bgColor="white"
            _hover={{ bg: "red.500", color: "white" }}
            onClick={() => setDisplay("none")}
          >
            <Link rel="canonical" to="/contact-us/">
              CONTACT
            </Link>
          </Button>
        </Flex>
      </Flex>
    </HStack>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default Header;
