import * as React from "react";
import { Link } from "gatsby";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

function Navigation() {
  return (
    <Flex color="Black" fontWeight="bold">
      <Button bgColor="white" _hover={{ bg: "red.500", color: "white" }}>
        <Link rel="canonical" to="/">
          HOME
        </Link>
      </Button>
      <Button bgColor="white" _hover={{ bg: "red.500", color: "white" }}>
        <Link rel="canonical" to="/about/">
          ABOUT
        </Link>
      </Button>
      <Menu>
        <MenuButton
          as={Button}
          bgColor="white"
          _hover={{ bg: "red.500", color: "white" }}
        >
          SERVICES
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Link to="/website-design/" rel="canonical">
              WEBSITE DESIGN
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/website-development/" rel="canonical">
              WEBSITE DEVELOPMENT
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/e-commerce-design-and-development/" rel="canonical">
              eCOMMERCE DESIGN & DEVELOPMENT
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/mobile-app-design-and-development/" rel="canonical">
              MOBILE APP DESIGN & DEVELOPMENT
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/progressive-or-responsive-web-app/" rel="canonical">
              PROGRESSIVE WEB APPLICATION
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/support-maintenance/" rel="canonical">
              WEBSITE MAINTENANCE
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton
          as={Button}
          bgColor="white"
          _hover={{ bg: "red.500", color: "white" }}
        >
          SOLUTIONS
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Link to="/third-party-integration/" rel="canonical">
              THIRD PARTY INTEGRATION
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/software-development/" rel="canonical">
              SOFTWARE DEVELOPMENT
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/support-maintenance/" rel="canonical">
              SUPPORT AND MAINTENENCE
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/cloud-we-work/" rel="canonical">
              CLOUD SOLUTIONS - WE WORK
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
      <Button bgColor="white" _hover={{ bg: "red.500", color: "white" }}>
        <Link rel="canonical" to="/contact-us/">
          CONTACT
        </Link>
      </Button>
    </Flex>
  );
}

export default Navigation;
