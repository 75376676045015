import React from "react";
import PropTypes from "prop-types";

function Container({ fluid, children, className, style }) {
  return (
    <div
      className={`${
        fluid ? "w-11/12 max-w-screen-2xl mx-auto" : "full max-w-auto mx-0"
      } ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fluid: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
};

Container.defaultProps = {
  fluid: false,
  className: "",
  style: {},
};

export default Container;
