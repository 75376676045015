import React from "react";
import { VStack, Center, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

function OurProcessCard({ title, icon: Icon }) {
  return (
    <VStack
      border="2px"
      borderColor="white"
      spacing="4"
      p="8"
      rounded="md"
      shadow="md"
    >
      <Center backgroundColor="white" rounded="full" p="6" color="black">
        {React.cloneElement(<Icon />, { size: 30 })}
      </Center>

      <Text as="h5" fontSize="xl" textTransform="uppercase">
        {title}
      </Text>
    </VStack>
  );
}

export default OurProcessCard;

OurProcessCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};
